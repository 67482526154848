/** Resize the font size of all elements with the class "fs-100" to fit its container.  */
export function resizeFont() {
    $(".fs-100:visible").each(function () {
        const $this = $(this);
        const $parent = $this.parent();
        /* Fix the parent container height */
        $parent.css("height", $parent.height());

        /* Get the container's inner width and height */
        const containerWidth = $this.width();
        const containerHeight = $this.height();

        /* Wrap the content and icon in a temporary span */
        const $span = $("<span/>").html($this.html()).css({
            "display": "inline-block",
            "white-space": "nowrap",
            "visibility": "hidden",
            "position": "absolute",
        }).appendTo($this);

        let fontSize = parseInt($this.css("font-size"), 10);

        /* Increase font size until the span is too big for the container */
        while ($span.width() <= containerWidth && $span.height() <= containerHeight && fontSize < 100) {
            fontSize++;
            $span.css("font-size", fontSize + "px");
        }

        /* Decrease font size if the span is larger than the container */
        while (($span.width() > containerWidth || $span.height() > containerHeight) && fontSize > 1) {
            fontSize--;
            $span.css("font-size", fontSize + "px");
        }

        this.style.setProperty("font-size", fontSize + "px", "important");

        /* Clean up: remove the temporary span */
        $span.remove();
    });
}

/** Initializes the resize font observer. */
function initResizeFontObserver() {
    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                resizeFont();
            }
        });
    });

    $(".fs-100").each(function () {
        observer.observe(this);
    });
}

resizeFont();
initResizeFontObserver();

$(window).on("resize", function () {
    resizeFont();
});
